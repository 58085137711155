.forget-password-container {
  display: flex;
  min-height: 100vh;
  max-width: 1920px;
  background-image: url("../../assets/images/login_bg.png");
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
}

.forget-password-content-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--color-white-70);
  width: calc(100% - 32px);
  max-width: 582px;
  padding: 44px;
  border-radius: 17px;
  box-shadow: var(--color-card-shadow);
  backdrop-filter: blur(16px);
  display: flex;
  flex-direction: column;
}

.title-forget-password {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 125% */
  letter-spacing: -0.96px;
  padding-left: 16px;
}

.dont-worry {
  color: var(--color-Text-neutral-medium);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.email-label {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.28px;
  color: var(--color-Text-neutral-strong);
  padding-bottom: 4px;
}

.check-your-email {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.72px;
}

.email-detail {
  color: var(--color-Text-neutral-strong);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

.didnt-recieve-email {
  color: var(--color-Text-neutral-medium);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.28px;
}

.count-down {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.28px;
}
