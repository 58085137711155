@keyframes colorChange {
  0% {
    background: linear-gradient(90deg, #02478E 60%, #829fbc 75%, #02478E 90%);
    background-size: 200% 200%;
    background-position: 100% 50%;
  }
  100% {
    background: linear-gradient(90deg, #02478E 5%, #829fbc 25%, #02478E 40%);
    background-size: 200% 200%;
    background-position: 0% 50%;
  }
}

.loader {
  height: 84px;
  width: 565px;
  background: linear-gradient(90deg, #02478E 5%, #829fbc 25%, #02478E 40%);
  mask-image: url('../../assets/images/trustplus_logo.svg');
  mask-size: cover;
  animation: colorChange 2s infinite linear;
  aspect-ratio: 7/2;
}
