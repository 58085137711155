:root {
  --color-bg-neutral-subtle: rgba(0, 0, 0, 0.06);
  --color-Text-neutral-strong: #121212;
  --color-absolute-white: #ffffff;
  --color-primary-700: #023972;
  --color-primary-500: #356ca5;
  --color-bg-disabled: #d1d1d1;
  --color-white-70: rgba(255, 255, 255, 0.7);
  --color-Text-neutral-medium: #525252;
  --color-card-shadow: 0px 4px 84px 0px #000e1c33;
  --color-primary-600: #02478e;
  --Black-Alpha-200: rgba(0, 0, 0, 0.11);
  --color-border-error-default: #f65737;
  --color-border-primary-default: #356ca5;
}
html {
  @media only screen and (min-width: 1920px) {
    font-size: 16px;
  }
  @media only screen and (max-width: 1536px) {
    font-size: 13px;
  }
}

body {
  min-height: 100vh;
  background: linear-gradient(180deg, #ffffff 0%, #cae1f8 100%);
  background-attachment: fixed;
  background-size: cover;
  margin: 0;
  font-size: 16px;
}

#root {
  display: flex;
  justify-content: center;
}

input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid var(--color-Text-neutral-strong);
  border-radius: 3px;
  outline: none;
  cursor: pointer;
  padding: 0;
}

input[type="checkbox"]:checked {
  background-color: var(--color-primary-700);
  border-color: var(--color-primary-700);
  position: relative;
}

input[type="checkbox"]:checked::after {
  content: "";
  position: absolute;
  left: 5px;
  top: 0px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

input[type="checkbox"]:hover {
  border-color: var(--color-primary-700);
}

input[type="checkbox"]:focus {
  box-shadow: 0 0 3px 2px rgba(0, 123, 255, 0.25);
}

input[type="checkbox"]:focus-visible {
  outline: 2px solid var(--color-primary-700);
  outline-offset: 2px;
}

.react-transform-wrapper,
.react-transform-component {
  width: 100%;
  height: auto;
}
