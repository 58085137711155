@tailwind base;
@tailwind components;
@tailwind utilities;

.text-gradient-aurora-blue {
  background: linear-gradient(134deg, #0269FF -0.25%, #9ADDEA 100.77%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-page-gradient {
  background: linear-gradient(180deg, #ffffff 0%, #cae1f8 100%);
}

.bg-card-gradient {
  background: linear-gradient(133.98deg, #6ca8ff -0.25%, #acf1ff 100.77%);
}

.primary-border-color {
  --tw-border-opacity: 1;
  /* border-color: rgb(53, 108, 165 / var(--tw-border-opacity)); */
  border-color: rgb(59 130 246 / var(--tw-border-opacity));
}
