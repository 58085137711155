.resetpassword-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 1920px;
  /* background-image: url("../../assets/images/login_bg.png"); */
  /* background-position: center;
  background-repeat: no-repeat; */
  padding: 16px;
  position: relative;
  width: 100%;
}

.line {
  width: 100%;
  height: 1px;
  background-color: var(--Black-Alpha-200);
  margin-top: 16px;
  margin-bottom: 44px;
}

.resetpassword-title {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 125% */
  letter-spacing: -0.96px;
  height: fit-content;
}

.resetpassword-content-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--color-white-70);
  width: calc(100% - 32px);
  max-width: 582px;
  padding: 44px;
  border-radius: 17px;
  box-shadow: var(--color-card-shadow);
  backdrop-filter: blur(16px);
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.resetpassword-aurora-logo {
  width: 138px;
  height: 38px;
}

.input-container {
  position: relative;
}
