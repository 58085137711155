.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.aurora-color {
  background-color: #02478e;
}

.App-header {
  background-color: #02478e;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.button {
  background-color: #003366; /* Dark Blue */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 12px;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #002244; /* Darker Blue */
}

.table-container {
  margin-top: 20px;
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 0.8125rem;
  text-align: left;
}

th,
td {
  padding: 12px;
}

td{
  font-size: 0.8125rem !important;
  height: 35px !important;
  line-height: 10px !important; /* 123.077% */
  letter-spacing: -0.26px !important;
}

th {
  background-color: #02478e; /* Dark Blue */
  /* color: white; */
}

tr {
  border-bottom: 1px solid #dddddd;
}

tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

tr:last-of-type {
  border-bottom: 2px solid #003366; /* Dark Blue */
}

tr:hover td div:not(.tooltip), tr:hover td {
  background-color: #f1f1f1 !important;
}

td.right-align {
  text-align: right;
}

/* Larger input styles */
.large-input {
  font-size: 1rem;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
}

.large-select {
  font-size: 1rem;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
}

/* Scroll bar */
::-webkit-scrollbar {
  width: 20px;
  height: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.hideScrollbar ::-webkit-scrollbar {
  width: 0px !important;
  background: transparent !; /* make scrollbar transparent */
}