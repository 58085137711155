.login-container {
  display: flex;
  min-height: 100vh;
  max-width: 1920px;
  background-image: url("../../assets/images/login_bg.png");
  background-position: center;
  background-repeat: no-repeat;
  padding: 1rem;
  position: relative;
  width: 100%;
}

.content-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--color-white-70);
  width: calc(100% - 32px);
  max-width: 582px;
  padding: 2.75rem;
  border-radius: 17px;
  box-shadow:0px 4px 84px 0px rgba(0, 14, 28, 0.20);
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.trust-logo {
  max-width: 215px;
  height: 32px;
}

.aurora-logo {
  max-width: 295.63px;
  height: auto;
  align-self: center;
  padding-bottom: 2.5rem;
  
}


.title {
  display: block;
  font-size: 1.5rem;
  font-weight: 600;
  align-self: center;
  text-align: center;
  margin-bottom: 5rem;
  line-height: 2rem; /* 133.333% */
  letter-spacing: -0.72px;
}

.sub-title {
  color: var(--color-Text-neutral-medium);
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.75rem;
  align-self: center;
  text-align: center;
  padding-bottom: 5rem;
}

.input-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.padding-bottom-40 {
  padding-bottom: 40px;
}

.forgot-password {
  color: var(--color-primary-600);
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  cursor: pointer;
  user-select: none;
}

.remember-me {
  color: var(--color-Text-neutral-strong);
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
}
